import { BrowserView, MobileView } from "react-device-detect"
import CartHeader from "../../Components/CartHeader"
import React, { useEffect, useRef, useState, useContext } from "react";
import Loader from "react-js-loader";
import DataContext from '../../Components/Elements/eventContext';
import multiCurrency from "../../Components/Elements/multi_currrency";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { ApiService } from "../../Components/Services/apiservices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import StripePaymentModal from "./stripe_payment_modal";
import Collapse from 'react-bootstrap/Collapse';
const CartCheckout = () => {
    const Navigate = useNavigate();
    const sessionData = sessionCartData();
    const contextValues = useContext(DataContext);
    const [settingData, setSettingData] = useState({});
    const [settingImagePath, setSettingImagePath] = useState("");
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [continuePaySpinnerLoading, setContinuePaySpinnerLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("pay-online");
    const [shippingCharge, setShippingCharge] = useState(0);
    const [shippingAvailibilityStatus, setShippingAvailibilityStatus] = useState(false);
    const [CODAvailibilityStatus, setCODAvailibilityStatus] = useState(false);
    const [textarea, setTextarea] = useState('');
    const [open, setOpen] = useState(false);
    const didMountRef = useRef(true)
    const [promoCode, setPromoCode] = useState({ promo_code: "", });
    const [couponSpinnerLoading, setCouponSpinnerLoading] = useState(false);
    useEffect(() => {
        if (didMountRef.current) {
            getSettingsData()
            contextValues.setCouponSession(sessionData[2])
            contextValues.setUserToken(localStorage.getItem("USER_TOKEN"))
            if (localStorage.getItem("USER_TOKEN")) {
                getUserAddress();
                cartSessionDataFetch();
            } else {
                const guestCheckOut = localStorage.getItem("GUEST_CHECKOUT");
                const guestCheckOutData = guestCheckOut ? JSON.parse(guestCheckOut) : {};
                if (sessionData[1] && sessionData[1].length > 0 && guestCheckOutData.user_address && guestCheckOutData.user_address.ua_mobile != '') {
                    contextValues.setGuestCheckoutData(guestCheckOutData)
                    contextValues.setAddressSession(guestCheckOutData.user_address)
                    console.log("guestCheckOutData.user_address", guestCheckOutData.user_address);
                    setShippingCharge(guestCheckOutData.shipping_amount)
                    contextValues.setCartCount(sessionData[1].length)
                    contextValues.setCartSessionData(sessionData[1])
                    contextValues.setCartSummary(sessionData[3])
                    const itemtotal = parseFloat(sessionData[3].itemTotal) - parseFloat(sessionData[3].discount) - parseFloat(sessionData[2] && sessionData[2].discount_amount ? sessionData[2].discount_amount : 0);
                    calculateShippingAmount(sessionData[1], itemtotal)
                    checkShippingAvailability(guestCheckOutData.user_address.ua_pincode)
                } else {
                    Navigate("/");
                }
            }
            setTimeout(() => {
                setSpinnerLoading(false);
            }, 500);
        }
        didMountRef.current = false;
    }, [contextValues]);



    const getUserAddress = () => {
        ApiService.fetchData("get-user-address").then((res) => {
            if (res.status === "success") {
                if (res.resAddressDefault && res.resAddressDefault.ua_id > 0) {
                    checkShippingAvailability(res.resAddressDefault.ua_pincode)
                    contextValues.setUserSelectedAddress(res.resAddressDefault);
                }
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        });
    };

    const cartSessionDataFetch = () => {
        const dataString = {
            product_id: '',
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                if (res.data.resCartData && res.data.resCartData.length > 0) {
                    contextValues.setCartSessionData(res.data.resCartData)
                    contextValues.setCartCount(res.data.resCartData.length)
                    contextValues.setCartSummary(res.data.cartSummary)
                    const itemtotal = parseFloat(res.data.cartSummary.itemTotal) - parseFloat(res.data.cartSummary.discount) - parseFloat(sessionData[2] && sessionData[2].discount_amount ? sessionData[2].discount_amount : 0);
                    calculateShippingAmount(res.data.resCartData, itemtotal)
                } else {
                    Navigate("/");
                }
            }
        });
    }

    const checkShippingAvailability = (pincode) => {
        const dataString = {
            ua_pincode: pincode
        }
        ApiService.postData("check-shipping-availability", dataString).then((res) => {
            if (res.status === "success") {
                setShippingAvailibilityStatus(res.shipping)
                setCODAvailibilityStatus(res.cod)
            } else {
                setShippingAvailibilityStatus(res.shipping)
                setCODAvailibilityStatus(res.cod)
                toast.error(res.notification);
            }
        });
    };

    const calculateShippingAmount = (cartSession, amount) => {
        const dataString = {
            itemtotal: amount,
            cart_data: cartSession,
        };
        ApiService.postData("calculate-shipping-amount", dataString).then((res) => {
            if (res.status === "success") {
                setShippingCharge(res.shipping_amount);
            }
        });
    };

    const handleChangeTextarea = (event) => {
        setTextarea(event.target.value);
    };



    const getSettingsData = () => {
        ApiService.fetchData("settings").then((res) => {
            if (res.status === "success") {
                setSettingData(res.sitesettings);
                setSettingImagePath(res.setting_image_path)
            }
        });
    };

    const editAddress = () => {
        Navigate('/address')
    }

    const selectPaymentMethod = (type) => {
        setPaymentMethod(type)
    }

    const ContinueToPay = () => {
        if (parseFloat(contextValues.cartSummary.itemTotal) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) <= settingData.admin_min_order) {
            toast.error("Minimum Order value AUD $" + settingData.admin_min_order);
            return false;
        }
        if (!shippingAvailibilityStatus) {
            toast.error("Sorry, we do not ship to this address. Try another one.");
            return false;
        }
        if (paymentMethod === 'COD') {
            const dataString = {
                amount: parseFloat(contextValues.cartSummary.itemTotal) + parseFloat(shippingCharge !== '' ? shippingCharge : 0) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0),
                paymentMethod: paymentMethod,
                shippingCharge: parseFloat(shippingCharge !== '' ? shippingCharge : 0),
                couponAmount: parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0),
                cartSummary: contextValues.cartSummary,
                parsedAddressSession: contextValues.userToken ? contextValues.userSelectedAddress : contextValues.addressSession,
                parsedCartSession: contextValues.cartSessionData,
                parsedCouponSession: contextValues.couponSession ? contextValues.couponSession : { discount_amount: 0.0, promo_id: 0, promo_code: "", cart_amount: 0.0 },
                textarea: textarea
            };
            setContinuePaySpinnerLoading(true)
            ApiService.postData("makecodorder", dataString).then((res) => {
                if (res.status === "success") { 
                    setContinuePaySpinnerLoading(false)
                    Navigate('/thankyou/' + res.order_number)
                } else {
                    setContinuePaySpinnerLoading(false)
                }
            });
        } else {
            const dataString = {
                amount: parseFloat(contextValues.cartSummary.itemTotal) + parseFloat(shippingCharge !== '' ? shippingCharge : 0) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0),
                paymentMethod: paymentMethod,
                shippingCharge: parseFloat(shippingCharge !== '' ? shippingCharge : 0),
                couponAmount: parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0),
                cartSummary: contextValues.cartSummary,
                parsedAddressSession: contextValues.userToken ? contextValues.userSelectedAddress : contextValues.addressSession,
                parsedCartSession: contextValues.cartSessionData,
                parsedCouponSession: contextValues.couponSession ? contextValues.couponSession : { discount_amount: 0.0, promo_id: 0, promo_code: "", cart_amount: 0.0 },
                textarea: textarea
            };
            setContinuePaySpinnerLoading(true)
            ApiService.postData("createtemporder", dataString).then((res) => {
                if (res.status === "success") {
                    contextValues.setStripeClientSecretKey(res.row_temp_trans.temp_clientsecret_key);
                    setTimeout(() => {
                        setContinuePaySpinnerLoading(false);
                        contextValues.setToggleStripePaymentModal(!contextValues.toggleStripePaymentModal)
                    }, 500);
                } else {
                    setContinuePaySpinnerLoading(false);
                }
            });
        }
    }

    /*******************COUPON SECTION*********************/
    const removeCoupon = () => {
        localStorage.removeItem("COUPON_SESSION");
        const couponSession = localStorage.getItem("COUPON_SESSION");
        const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {
            discount_amount: 0.0,
            promo_id: 0,
            promo_code: "",
        };
        contextValues.setCouponSession(parsedCouponSession)
        //window.location.reload();
    }

    const onTodoChangePromo = (e) => {
        const { name, value } = e.target;
        setPromoCode((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const applyCouponTyping = () => {
        if (promoCode.promo_code == '') {
            toast.error("Please enter Coupon Code");
            return false;
        }
        const productData = {
            promo_code: promoCode.promo_code,
            cart_total: contextValues.cartSummary && contextValues.cartSummary.sellingTotal ? contextValues.cartSummary.sellingTotal : 0,
        };
        setCouponSpinnerLoading(true)
        ApiService.postData("select-coupon", productData).then((res) => {
            if (res.data.status === 'success') {
                toast.success(res.data.notification)
                localStorage.removeItem("COUPON_SESSION");
                const couponSessionObj = {
                    discount_amount: res.data.discount_amount,
                    promo_id: res.data.promo_id,
                    promo_code: res.data.promo_code,
                };
                localStorage.setItem("COUPON_SESSION", JSON.stringify(couponSessionObj));
                contextValues.setCouponSession(couponSessionObj);
                //window.location.reload();
                setCouponSpinnerLoading(false)
                setPromoCode((prevState) => ({
                    ...prevState,
                    ['promo_code']: '',
                }));
            } else {
                toast.error(res.data.notification)
                setCouponSpinnerLoading(false)
            }
        }).catch((error) => {
            toast.error(error)
            setCouponSpinnerLoading(false)
        });
    }
    /*******************END*********************/
    return (<>
        <CartHeader />
        {spinnerLoading ? <SpinnerLoader /> :
            <>
                <BrowserView>
                    <div className="cartSection">
                        <div className="container">
                            <div className="left">
                                <div className="p-30">
                                    <div className="row g-3 mb-30">
                                        <div className="col-lg-12">
                                            {contextValues.cartSessionData.length > 0 && (
                                                <>
                                                    {/* Address Section */}
                                                    {contextValues.userToken ?
                                                        <div className="mb-15">
                                                            <div className="cartSectionTitle mb-20">
                                                                <h5 className="mb-0 tx-16">Shipping Details</h5>
                                                                <a href="javascript:void(0);" className="tx-primary" onClick={(e) => editAddress()}>Change</a>
                                                            </div>
                                                            <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                                                                <h6 className="mb-2 tx-14">{contextValues.userSelectedAddress.ua_fname} {contextValues.userSelectedAddress.ua_lname}</h6>
                                                                <p className="mb-1 tx-12">{contextValues.userSelectedAddress.ua_house_no}, {contextValues.userSelectedAddress.ua_city_name}, {contextValues.userSelectedAddress.ua_state_name}, {contextValues.userSelectedAddress.ua_pincode}</p>
                                                                <p className="tx-gray mb-0 tx-12">Mobile No: {contextValues.userSelectedAddress.ua_mobile}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="mb-15">
                                                            <div className="cartSectionTitle mb-20">
                                                                <h5 className="mb-0 tx-16">Shipping Details</h5>
                                                                <a href="javascript:void(0);" className="tx-primary" onClick={(e) => editAddress()}>Change</a>
                                                            </div>
                                                            <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                                                                <h6 className="mb-2 tx-14">{contextValues.addressSession.ua_fname} {contextValues.addressSession.ua_lname}</h6>
                                                                <p className="mb-1 tx-12">{contextValues.addressSession.ua_house_no}, {contextValues.addressSession.ua_city_name}, {contextValues.addressSession.ua_state_name}, {contextValues.addressSession.ua_pincode}</p>
                                                                <p className="tx-gray mb-0 tx-12">Mobile No: {contextValues.addressSession.ua_mobile}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="mb-15">
                                                        <div className="cartSectionTitle mb-10">
                                                            <h5 className="mb-0 tx-16">Order Note</h5>
                                                        </div>
                                                        <div className="form-group">
                                                            <textarea name="textarea" placeholder="How can we help you?" value={textarea} onChange={handleChangeTextarea}></textarea>
                                                        </div>
                                                    </div>
                                                    {/* Payment Section */}
                                                    <div className="mb-15">
                                                        <div className="cartSectionTitle mb-10">
                                                            <h5 className="mb-0 tx-16">Payment Options</h5>
                                                        </div>
                                                        {CODAvailibilityStatus &&
                                                            <div className="paymentoptionbox mb-10" onClick={(e) => selectPaymentMethod('COD')}>
                                                                <div className="paymentoptionboxIcon">
                                                                    <img className="wd-38" src="/img/delivery.png" />
                                                                    <div className="ml-15">
                                                                        <h6 className="mb-0 tx-14">Cash On Delivery</h6>
                                                                        <p className="mb-0 tx-gray tx-12">Pay with cash</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <input type="radio" name="group" checked={paymentMethod === 'COD' ? true : false} />
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="paymentoptionbox mb-10" onClick={(e) => selectPaymentMethod('pay-online')}>
                                                            <div className="paymentoptionboxIcon">
                                                                <img className="wd-38" src="/img/phonepe.png" />
                                                                <div className="ml-15">
                                                                    <h6 className="mb-0 tx-14">Pay Online</h6>
                                                                    <p className="mb-0 tx-gray tx-12">Pay with debit/credit card</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <input type="radio" name="group" checked={paymentMethod === 'pay-online' ? true : false} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-15">
                                                        <button className="btn btn-primary btn-block btn-large" type="button" onClick={(e) => ContinueToPay()}>{continuePaySpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : 'Continue to Pay'}</button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="right">
                                <div className="p-30">
                                    {contextValues.cartSessionData.map((value, index) => {
                                        return (
                                            <div className="checkoutProduct" key={index}>
                                                <figure className="checkoutProductMedia">
                                                    <a href={"/product/" + value.product_slug}>
                                                        <img src={value.product_image}></img>
                                                    </a>
                                                </figure>
                                                <div className="checkoutProductContent">
                                                    <h2 className="title mb-1">{value.product_name}</h2>
                                                    <div className="cpcvar mb-10">
                                                        {/* <div><span className="tx-gray">Size:</span> </div> */}
                                                        <div><span className="tx-gray">Qty:</span> {value.quantity}</div>
                                                    </div>
                                                    <div className="price">
                                                        <ins className="new-price">{multiCurrency(value.product_selling_price)}</ins>
                                                        {value.product_discount > 0 && (<del className="old-price">{multiCurrency(value.product_price)}</del>)}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {/* Coupon */}
                                    <div className="cartSectionCoupon mb-20">
                                        <input type="text" placeholder="Enter Coupon Code"
                                            name="promo_code"
                                            value={promoCode.promo_code}
                                            onChange={(e) => onTodoChangePromo(e)}
                                        ></input>
                                        <button type="button" className="applybutton" onClick={(e) => applyCouponTyping()}>{couponSpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : 'Apply'}</button>
                                    </div>
                                    {contextValues.couponSession && contextValues.couponSession.promo_id > 0 &&
                                        <div className="d-flex justify-content-between mb-20">
                                            <div>
                                                <h6 className="tx-11 mb-0">{contextValues.couponSession.promo_code} applied successfully</h6>
                                                <span className="tx-12 tx-green">You save <span className="tx-green">{contextValues.couponSession.discount_amount}</span> on this order!</span>
                                            </div>
                                            <div className="itemscount" style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg"></i></div>
                                        </div>

                                    }
                                    <ul className="cartSectionPrice">
                                        <li>
                                            <span>Subtotal</span>
                                            <span>{multiCurrency(contextValues.cartSummary.itemTotal)}</span>
                                        </li>
                                        {contextValues.cartSummary && contextValues.cartSummary.discount > 0 && (
                                            <li>
                                                <span>Discount on MRP</span>
                                                <span className="tx-green">-{multiCurrency(contextValues.cartSummary.discount)}</span>
                                            </li>
                                        )}
                                        {contextValues.couponSession && contextValues.couponSession.discount_amount > 0 && (
                                            <li>
                                                <span>Coupan Discount</span>
                                                <span className="tx-green">-{multiCurrency(contextValues.couponSession.discount_amount)}</span>
                                            </li>
                                        )}
                                        <li>
                                            <span>Shipping</span>
                                            <span>{shippingCharge !== '' ? multiCurrency(shippingCharge) : 'Calculated at next step'}</span>
                                        </li>
                                        <hr></hr>
                                        <li className="bigrow">
                                            <span>Total Amount</span>
                                            <span>{multiCurrency(parseFloat(contextValues.cartSummary.itemTotal) + parseFloat(shippingCharge !== '' ? shippingCharge : 0) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0))}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="pb-5 mb-3">
                        <div className="ordersummaryToggle">
                            <div className="ordersummaryToggleHed" onClick={() => setOpen(!open)} aria-expanded={open}>
                                <div>
                                    <h6 className="mb-0 tx-14"><i className="ri-shopping-cart-line"></i> Order Summary</h6>
                                </div>
                                <div>{multiCurrency(parseFloat(contextValues.cartSummary.itemTotal) + parseFloat(shippingCharge !== '' ? shippingCharge : 0) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0))} <i className="ri-arrow-down-s-line"></i></div>
                            </div>
                            <Collapse in={open}>
                                <div className="ordersummaryTogglecont">
                                    <div className="p-15">
                                        {contextValues.cartSessionData.map((value, index) => {
                                            return (
                                                <div className="checkoutProduct" key={index}>
                                                    <figure className="checkoutProductMedia">
                                                        <a href={"/product/" + value.product_slug}>
                                                            <img src={value.product_image}></img>
                                                        </a>
                                                    </figure>
                                                    <div className="checkoutProductContent">
                                                        <h2 className="title mb-1">{value.product_name}</h2>
                                                        <div className="cpcvar mb-10">
                                                            {/* <div><span className="tx-gray">Size:</span> </div> */}
                                                            <div><span className="tx-gray">Qty:</span> {value.quantity}</div>
                                                        </div>
                                                        <div className="price">
                                                            <ins className="new-price">{multiCurrency(value.product_selling_price)}</ins>
                                                            {value.product_discount > 0 && (<del className="old-price">{multiCurrency(value.product_price)}</del>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <ul className="cartSectionPrice">
                                            <li>
                                                <span>Subtotal</span>
                                                <span>{multiCurrency(contextValues.cartSummary.itemTotal)}</span>
                                            </li>
                                            {contextValues.cartSummary && contextValues.cartSummary.discount > 0 && (
                                                <li>
                                                    <span>Discount on MRP</span>
                                                    <span className="tx-green">-{multiCurrency(contextValues.cartSummary.discount)}</span>
                                                </li>
                                            )}
                                            {contextValues.couponSession && contextValues.couponSession.discount_amount > 0 && (
                                                <li>
                                                    <span>Coupan Discount</span>
                                                    <span className="tx-green">-{multiCurrency(contextValues.couponSession.discount_amount)}</span>
                                                </li>
                                            )}
                                            <li>
                                                <span>Shipping</span>
                                                <span>{shippingCharge !== '' ? multiCurrency(shippingCharge) : 'Calculated at next step'}</span>
                                            </li>
                                            <hr></hr>
                                            <li className="bigrow">
                                                <span>Total Amount</span>
                                                <span>{multiCurrency(parseFloat(contextValues.cartSummary.itemTotal) + parseFloat(shippingCharge !== '' ? shippingCharge : 0) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0))}</span>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </Collapse>
                        </div>
                        <div className="cardPanel">
                            <div className="cardPanelBody">
                                {contextValues.userToken ?
                                    <div className="mb-15">
                                        <div className="cartSectionTitle mb-20">
                                            <h5 className="mb-0 tx-16">Shipping Details</h5>
                                            <a href="javascript:void(0);" className="tx-primary" onClick={(e) => editAddress()}>Change</a>
                                        </div>
                                        <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                                            <h6 className="mb-2 tx-14">{contextValues.userSelectedAddress.ua_fname} {contextValues.userSelectedAddress.ua_lname}</h6>
                                            <p className="mb-1 tx-12">{contextValues.userSelectedAddress.ua_house_no}, {contextValues.userSelectedAddress.ua_city_name}, {contextValues.userSelectedAddress.ua_state_name}, {contextValues.userSelectedAddress.ua_pincode}</p>
                                            <p className="tx-gray mb-0 tx-12">Mobile No: {contextValues.userSelectedAddress.ua_mobile}</p>
                                        </div>
                                    </div>
                                    :
                                    <div className="mb-15">
                                        <div className="cartSectionTitle mb-20">
                                            <h5 className="mb-0 tx-16">Shipping Details</h5>
                                            <a href="javascript:void(0);" className="tx-primary" onClick={(e) => editAddress()}>Change</a>
                                        </div>
                                        <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                                            <h6 className="mb-2 tx-14">{contextValues.addressSession.ua_fname} {contextValues.addressSession.ua_lname}</h6>
                                            <p className="mb-1 tx-12">{contextValues.addressSession.ua_house_no}, {contextValues.addressSession.ua_city_name}, {contextValues.addressSession.ua_state_name}, {contextValues.addressSession.ua_pincode}</p>
                                            <p className="tx-gray mb-0 tx-12">Mobile No: {contextValues.addressSession.ua_mobile}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="cardPanel">
                            <div className="cardPanelBody">
                                <div className="mb-15">
                                    <div className="cartSectionTitle mb-10">
                                        <h5 className="mb-0 tx-16">Order Note</h5>
                                    </div>
                                    <div className="form-group">
                                        <textarea name="textarea" placeholder="How can we help you?" value={textarea} onChange={handleChangeTextarea}></textarea>
                                    </div>
                                </div>
                                {/* Payment Section */}
                                <div className="mb-15">
                                    <div className="cartSectionTitle mb-10">
                                        <h5 className="mb-0 tx-16">Payment Options</h5>
                                    </div>
                                    {CODAvailibilityStatus &&
                                        <div className="paymentoptionbox mb-10" onClick={(e) => selectPaymentMethod('COD')}>
                                            <div className="paymentoptionboxIcon">
                                                <img className="wd-38" src="/img/delivery.png" />
                                                <div className="ml-15">
                                                    <h6 className="mb-0 tx-14">Cash On Delivery</h6>
                                                    <p className="mb-0 tx-gray tx-12">Pay with cash</p>
                                                </div>
                                            </div>
                                            <div>
                                                <input type="radio" name="group" checked={paymentMethod === 'COD' ? true : false} />
                                            </div>
                                        </div>
                                    }
                                    <div className="paymentoptionbox mb-10" onClick={(e) => selectPaymentMethod('pay-online')}>
                                        <div className="paymentoptionboxIcon">
                                            <img className="wd-38" src="/img/phonepe.png" />
                                            <div className="ml-15">
                                                <h6 className="mb-0 tx-14">Pay Online</h6>
                                                <p className="mb-0 tx-gray tx-12">Pay with debit/credit card</p>
                                            </div>
                                        </div>
                                        <div>
                                            <input type="radio" name="group" checked={paymentMethod === 'pay-online' ? true : false} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cardPanel">
                            <div className="cardPanelBody">
                                {/* Coupon */}
                                <div className="cartSectionCoupon mb-20">
                                    <input type="text" placeholder="Enter Coupon Code"
                                        name="promo_code"
                                        value={promoCode.promo_code}
                                        onChange={(e) => onTodoChangePromo(e)}
                                    ></input>
                                    <button type="button" className="applybutton" onClick={(e) => applyCouponTyping()}>{couponSpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : 'Apply'}</button>
                                </div>
                                {contextValues.couponSession && contextValues.couponSession.promo_id > 0 &&
                                    <div className="d-flex justify-content-between mb-20">
                                        <div>
                                            <h6 className="tx-11 mb-0">{contextValues.couponSession.promo_code} applied successfully</h6>
                                            <span className="tx-12 tx-green">You save <span className="tx-green">{contextValues.couponSession.discount_amount}</span> on this order!</span>
                                        </div>
                                        <div className="itemscount" style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg"></i></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="footer-checkout">
                        <button className="btn btn-primary btn-block btn-large" type="button" onClick={() => ContinueToPay()}>{continuePaySpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : 'Continue'}</button>
                    </div>
                </MobileView>
                {contextValues.toggleStripePaymentModal && (<StripePaymentModal />)}
            </>}
    </>)
}


export default CartCheckout