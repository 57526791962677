import React, { useEffect, useState, useRef, useContext } from "react";
import DataContext from "../Elements/eventContext";
import { ApiService } from "../Services/apiservices";
import Loader from "react-js-loader";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { validNumber } from "../Elements/Regex";
function CheckoutAddressModal() {
    const didMountRef = useRef(true);
    const contextValues = useContext(DataContext);
    const [spinnerLoading, setspinnerLoading] = useState(false);
    const [firstnameFocused, setfirstnameFocused] = useState(false);
    const [lastnameFocused, setlastnameFocused] = useState(false);
    const [mobilenumberFocused, setmobilenumberFocused] = useState(false);
    const [addressFocused, setaddressFocused] = useState(false);
    const [postalFocused, setpostalFocused] = useState(false);
    const [stateFocused, setstateFocused] = useState(false);
    const [cityFocused, setcityFocused] = useState(false);
    const [countryFocused, setcountryFocused] = useState(false);
    const [addressTypeFocused, setaddressTypeFocused] = useState(false);
    const [addressTypeOtherFocused, setaddressTypeOtherFocused] = useState(false);
    const [shippingAvailibilityStatus, setShippingAvailibilityStatus] = useState(false);
    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_user_id: 0,
        ua_fname: "",
        ua_lname: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_house_no: "",
        ua_state_id: "",
        ua_city_id: "",
        ua_state_name: "",
        ua_city_name: "",
        ua_country_id: "13",
        ua_address_type: "",
        ua_address_type_other: "",
        ua_bill_fname: "",
        ua_bill_lname: "",
        ua_bill_email: "",
        ua_bill_mobile: "",
        ua_bill_pincode: "",
        ua_bill_house_no: "",
        ua_bill_state_id: "",
        ua_bill_city_id: "",
        ua_bill_state_name: "",
        ua_bill_city_name: "",
        ua_bill_country_id: "13",
        ua_billing_address_check: true
    });

    useEffect(() => {
        if (didMountRef.current) {
        }
        didMountRef.current = false;
    }, []);

    const toggleCheckoutAddressModal = () => {
        contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
    }

    const onTodoRegChange = (e) => {
        const { name, value } = e.target;
        setUserAddressDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === 'ua_pincode' || name === 'ua_bill_pincode') {
            if (value.length === 6) {
                checkShippingAvailability(value)
            }
        }
    };

    const checkShippingAvailability = (pincode) => {
        const dataString = {
            ua_pincode: pincode
        }
        ApiService.postData("check-shipping-availability", dataString).then((res) => {
            if (res.status === "success") {
                setShippingAvailibilityStatus(res.shipping)
            } else {
                setShippingAvailibilityStatus(res.shipping)
                toast.error(res.notification);
            }
        });
    };

    const handleAddressProcess = () => {
        if (userAddressDetails.ua_fname === "") {
            toast.error("Please enter First Name");
            return false;
        }
        if (userAddressDetails.ua_lname === "") {
            toast.error("Please enter Last Name");
            return false;
        }
        if (!validNumber.test(userAddressDetails.ua_mobile)) {
            toast.error("Please enter valid Mobile Number");
            return false;
        }

        if (userAddressDetails.ua_house_no === "") {
            toast.error("Please enter Address (Villa/Apt#, Building Name & Street)");
            return false;
        }
        if (userAddressDetails.ua_pincode === "") {
            toast.error("Please enter Pincode");
            return false;
        }
        if (userAddressDetails.ua_state_name === "") {
            toast.error("Please enter State Name");
            return false;
        }
        if (userAddressDetails.ua_city_name === "") {
            toast.error("Please enter City Name");
            return false;
        }
        if (!shippingAvailibilityStatus) {
            toast.error("Sorry, we do not ship to this address. Try another one.");
            return false;
        }
        setspinnerLoading(true);
        ApiService.postData("user-address-process", userAddressDetails).then(
            (res) => {
                if (res.status == "success") {
                    contextValues.setUserAddressList(res.resAddressData);
                    setTimeout(() => {
                        setspinnerLoading(false);
                        window.location.reload();
                    }, 500);
                } else {
                    toast.error(res.message);
                    setspinnerLoading(false);
                }
            }
        );
    };


    return (
        <>
            <Modal show={contextValues.toggleCheckoutAddressModal} onHide={(e) => toggleCheckoutAddressModal()} className="addressModal">
            <button type="button" className="pop-close" onClick={(e) => toggleCheckoutAddressModal()}></button>
                    
               <div className="p-15">
               <div className="mb-3 addressModal-header">
                <h5 className="tx-theme mb-1">Add New Address</h5>
                <p className="tx-color-02 tx-12">Add your home and office addresses and enjoy faster checkout</p>
                </div>
                <div className="row g-3">
                            <div className="col-lg-6">
                                <div className="flotting-group mb-1">
                                    <label className={`${firstnameFocused || userAddressDetails.ua_fname ? "label" : ""} `}>First Name</label>
                                    <input type="text" className={` required form-control`}
                                        name='ua_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                                        onFocus={(e) => setfirstnameFocused(true)}
                                        onBlur={(e) => setfirstnameFocused(false)}
                                    ></input>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="flotting-group mb-1">
                                    <label className={lastnameFocused || userAddressDetails.ua_lname ? "label" : ""}>Last Name</label>
                                    <input type="text" className="required form-control" placeholder="Last Name" name='ua_lname'
                                        onFocus={(e) => setlastnameFocused(true)}
                                        onBlur={(e) => setlastnameFocused(false)}
                                        onChange={(e) => onTodoRegChange(e)}></input>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="flotting-group mb-1">
                                    <label className={mobilenumberFocused || userAddressDetails.ua_mobile ? "label" : ""}>Mobile Number</label>
                                    <input type="number" className="required form-control" name="ua_mobile" placeholder="Mobile Number"
                                        onChange={(e) => onTodoRegChange(e)}
                                        onFocus={(e) => setmobilenumberFocused(true)}
                                        onBlur={(e) => setmobilenumberFocused(false)}></input>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="flotting-group mb-1">
                                    <label className={addressFocused || userAddressDetails.ua_house_no ? "label" : ""}>Address</label>
                                    <input type="text" className="required form-control" name="ua_house_no"
                                        placeholder="Address (Villa/Apt#, Building Name & Street)"
                                        onFocus={(e) => setaddressFocused(true)}
                                        onBlur={(e) => setaddressFocused(false)}
                                        onChange={(e) => onTodoRegChange(e)}></input>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="flotting-group mb-1">
                                    <label className={postalFocused || userAddressDetails.ua_pincode ? "label" : ""}>Postal Code</label>
                                    <input type="number" className="required form-control"
                                        placeholder="Postal Code" name="ua_pincode"
                                        onFocus={(e) => setpostalFocused(true)}
                                        onBlur={(e) => setpostalFocused(false)}
                                        onChange={(e) => onTodoRegChange(e)}></input>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="flotting-group mb-1">
                                    <label className={countryFocused || userAddressDetails.ua_country_id ? "label" : ""}>Country</label>
                                    <input type="text" className="required form-control" name="ua_country_id" disabled={true}
                                        value={'Australia'}
                                        onFocus={(e) => setcountryFocused(true)}
                                        onBlur={(e) => setcountryFocused(false)}
                                        onChange={(e) => onTodoRegChange(e)}></input>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="flotting-group mb-1">
                                    <label className={stateFocused || userAddressDetails.ua_state_name ? "label" : ""}>State</label>
                                    <input type="text" className="required form-control"
                                        name="ua_state_name"
                                        placeholder="State"
                                        onFocus={(e) => setstateFocused(true)}
                                        onBlur={(e) => setstateFocused(false)}
                                        onChange={(e) => onTodoRegChange(e)}></input>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="flotting-group mb-1">
                                    <label className={cityFocused || userAddressDetails.ua_city_name ? "label" : ""}>City</label>
                                    <input type="text" className=" required form-control" name="ua_city_name"
                                        onChange={(e) => onTodoRegChange(e)}
                                        placeholder="City"
                                        onFocus={(e) => setcityFocused(true)}
                                        onBlur={(e) => setcityFocused(false)}
                                    ></input>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="flotting-group mb-1"> 
                                    <label className={addressTypeFocused || userAddressDetails.ua_address_type ? "label" : ""}>Address Type</label>
                                    <select name="ua_address_type" className="form-control required" value={userAddressDetails.ua_address_type} 
                                        onChange={(e) => onTodoRegChange(e)}
                                        onFocus={(e) => setaddressTypeFocused(true)}
                                        onBlur={(e) => setaddressTypeFocused(false)}>
                                        <option value="">Address Type</option>
                                        <option value="Home">Home</option>
                                        <option value="Work">Work</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            {userAddressDetails.ua_address_type === 'Other' &&
                                <div className='col-lg-12'>
                                    <div className="flotting-group mb-1"> 
                                    <label className={addressTypeOtherFocused || userAddressDetails.ua_address_type_other ? "label" : ""}>Other</label>
                                    <input type="text" name="ua_address_type_other" className="form-control required" 
                                        value={userAddressDetails.ua_address_type_other}
                                        onChange={(e) => onTodoRegChange(e)}
                                        onFocus={(e) => setaddressTypeOtherFocused(true)}
                                        onBlur={(e) => setaddressTypeOtherFocused(false)}
                                        placeholder="Other"
                                    />
                                    </div>
                                </div>
                            }
                            <div className="col-lg-12">
                                <button type="button" className="btn btn-primary btn-block btn-large btn-checkoo" onClick={(e) => handleAddressProcess()} disabled={spinnerLoading}>{spinnerLoading ? <div className="pageLoder"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /></div> : 'Add Address'}</button>
                            </div>
                        </div>
               </div>
               
                
            </Modal>

        </>
    );
}
export default CheckoutAddressModal;
